import React, {  useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';git 

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';



import "swiper/css/effect-fade";
// import Swiper from 'swiper/bundle';
// import 'swiper/css/bundle';

// ----------------Gallery slider import------------------
import ImageGallery from 'react-image-gallery';
import { ModalProvider } from 'styled-react-modal';
import { Button, Modal } from 'antd';
import RegisterModal from '../Popup/RegisterModal';



// function HomeNew() {

  

//   const [showIframe, setShowIframe] = useState(false);

  

//   const handleVideoClick = () => {
//     setShowIframe(true);
//   };
//   const handleReadMoreClick = () => {
//     setShowIframe(true);
//   };
//   const handleMouseEnter = (e) => {
//     e.target.play();
//   };

//   const handleMouseLeave = (e) => {
//     e.target.pause();
//   };



//   const [isMuted, setIsMuted] = useState(true);
//   const [isActive, setActive] = useState("false");

//   const ToggleClass = () => {
//     setActive(!isActive);
//   };


function HomeNew() {
  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());
  
  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {
    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => console.error('Play interrupted:', error));
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        // Attempt to pause the video and handle cases where pause() might not return a Promise
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch(error => console.error('Pause interrupted:', error));
        }
      }
    }
  };

  const handleVideoClick = () => {
    setShowIframe(true);
  };

  const handleReadMoreClick = () => {
    setShowIframe(true);
  };

  

  // const handleViewportChange = (videoElement) => {
  //   if (isMobile) {
  //     const handleScroll = () => {
  //       const rect = videoElement.getBoundingClientRect();
  //       const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
  //       if (isInViewport) {
  //         videoElement.play();
  //       } else {
  //         videoElement.pause();
  //       }
  //     };

  //     window.addEventListener('scroll', handleScroll);
  //     handleScroll();

  //     return () => window.removeEventListener('scroll', handleScroll);
  //   } else {
  //     videoElement.pause(); // Ensure it is paused on desktop
  //   }
  // };

  // useEffect(() => {
  //   const handleResize = () => setIsMobile(window.innerWidth <= 768);

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // useEffect(() => {
  //   if (videoRef1.current) handleViewportChange(videoRef1.current);
  //   if (videoRef2.current) handleViewportChange(videoRef2.current);
  //   if (videoRef3.current) handleViewportChange(videoRef3.current);
  //   if (videoRef4.current) handleViewportChange(videoRef4.current);
  // }, [isMobile]);

  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState(false);

  const ToggleClass = () => {
    setActive(!isActive);
  };


  return (
    <>
      <Header />

      <section className="oxfordBanner">
        <div>
          {/* <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}  playsInline>
          
            <source src="https://upcdn.io/kW15cAe/raw/Main%20Banner.mp4" type="video/mp4" />
          </video> */}

          <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted}>
            <source src="https://muVid.b-cdn.net/mainbanner.mp4" type="video/mp4" />
          </video>



        </div>
        <div className="banner-content">
          {/* <h1>OxfordMUN India</h1> */}
          <div className={isActive ? "mute" : "unmute"}>
            <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg     " alt="unMuteIcon" />
              <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
          </div>
        </div>
      </section >

      <section className="p-80-20 bgBlack ourPillarsSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">The Cornerstones</h6>
            <h2 className="fs-80 fw-600 textWhite">Our Pillars</h2>
          </div>
          <div className="ourPillars-outer">


          <div className="pillars">
              {/* <video className="bgPillarsImg" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1721459700/MU_new_Compressed_video/Pillar/Summit_Pillae_gkl8gk.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video> */}

              <a href="/summits"  rel="noopener noreferrer">
                  <video className="bgPillarsImg video" width="400" muted onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick} playsInline>
                    <source src="https://muVid.b-cdn.net/summitpillae.mp4" type="video/mp4" />                                 
                    <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                  </video>

                  
              </a>

              <div className="titleTop">
                <h4 className="fs-34 fw-700 bgDarkRed pb-10 pt-10  textWhite">Summits</h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Summits</h3> */}
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                MU20 Opportunity Summits bring together students from around the world to engage in diverse challenges, fostering leadership, innovation, and global networking.
                </p>
                <a href="/summits" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>





            <div className="pillars">
                <a href="/bootcamp_pillar"  rel="noopener noreferrer">
                    <video className="bgPillarsImg video" width="400" muted  onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick} playsInline  >
                      <source src="https://muVid.b-cdn.net/summitpillae.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
                </a>
              <div className="titleTop">
                <h4 className="fs-34 fw-700 bgOrg pb-10 pt-10  textWhite">Bootcamp</h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Bootcamp</h3> */}
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                MU20 Bootcamps offer immersive, hands-on experiences that combine adventure activities with leadership training, promoting holistic personal growth.
                </p>
                <a href="/bootcamp_pillar" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>

            <div className="pillars">
              <a href="/online_challenges"  rel="noopener noreferrer">
                    <video className="bgPillarsImg video" width="400" muted  onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick} playsInline >
                      <source src="https://muVid.b-cdn.net/summitpillae.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
              </a>      
              <div className="titleTop">
                <h4 className="fs-34 fw-700 pb-10 pt-10  textWhite bgYellow">Online Challenges</h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Online Challenges</h3> */}
              </div>
              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                MU20 Online Challenges allow students to compete in various creative and intellectual tasks from anywhere in the world, enhancing their skills and global engagement.

                </p>
                <a href="/online_challenges" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>

            <div className="pillars">
              <a href="/Residential_program"  rel="noopener noreferrer">
                    <video className="bgPillarsImg video" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                      <source src="https://muVid.b-cdn.net/summitpillae.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
              </a>
              <div className="titleTop">
                <h4 className="fs-34 fw-700 pb-10 pt-10  textWhite     bgSkyBlue">Residential Programs</h4>
                {/* <h3 className="fs-34 fw-700 textWhite">Residential Programs</h3> */}
              </div>

              <div className="content">
                <p className="fs-22 fw-500 textWhite">
                MU20 Residential Programs provide an intensive learning environment where students live on-site, participate in structured activities, and build lifelong skills and connections.
                </p>
                <a href="/Residential_program" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>

            
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack feacProgramSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Explore</h6>
            <h2 className="fs-80 fw-600 textWhite">Featured Program</h2>
          </div>
          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              }
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            speed={5000}
            slidesPerView={3}
            centerInsufficientSlides={true}
            navigation={false}
            loop={true}            
            // autoplay={{
            //   delay: 2000,
            // }}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
              pauseOnMouseEnter: true
            }}
            freeMode={false} 
            centeredSlides={true}

          >
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/MU20OpportunitySummit24"  rel="noopener noreferrer">
                  <video  className="sliderBgImage video" width="400" muted={isMuted}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleVideoClick} playsInline> 
                    <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                    <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                  </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">MU20 Opportunity Summit
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Engage in diverse challenges and workshops that foster leadership, innovation, and global networking.
                    </p>
                  </div>
                  <a href="/Summits" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/Young_Leaders_Bootcamp"  rel="noopener noreferrer">
                <video  className="sliderBgImage video" width="400" muted={isMuted} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleVideoClick} playsInline >
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Young Leader's Bootcamp (YLB)

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Develop leadership skills and personal growth through immersive and comprehensive  learning experiences.
                    </p>
                  </div>
                  <a href="/Young_Leaders_Bootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/LeadershipandAdventureBootcamp"  rel="noopener noreferrer">
                  <video  className="sliderBgImage video" width="400" muted={isMuted}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleVideoClick} playsInline>
                    <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                    <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                  </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Leadership and Adventure Bootcamp (LAB)

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Push your limits with thrilling activities and intensive leadership workshops in an immersive setting.
                    </p>
                  </div>
                  <a href="/LeadershipandAdventureBootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/MU20OpportunitySummit24"  rel="noopener noreferrer">
                  <video className="sliderBgImage video" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                    <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                    <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                  </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">MU20 Opportunity Summit
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Engage in diverse challenges and workshops that foster leadership, innovation, and global networking.
                    </p>
                  </div>
                  <a href="/Summits" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/Young_Leaders_Bootcamp" rel="noopener noreferrer">
                  <video className="sliderBgImage video" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                    <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                    <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                  </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Young Leader's Bootcamp (YLB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Develop leadership skills and personal growth through immersive, hands-on training experiences.
                    </p>
                  </div>
                  <a href="Young_Leaders_Bootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/LeadershipandAdventureBootcamp"  rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                      Leadership and Adventure Bootcamp (LAB)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Push your limits with thrilling activities and intensive leadership workshops in an immersive setting.
                    </p>
                  </div>
                  <a href="/LeadershipandAdventureBootcamp" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>


            {/* <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                  <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1719826600/videos/new_vid/lxudm0qtu2dzi9aybftp.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
                  <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1719826600/videos/new_vid/lxudm0qtu2dzi9aybftp.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>

          <Swiper className="feacProgram-outerCenter"
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              }
            }}

            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            speed={5000}
            centerInsufficientSlides={true}
            navigation={false}
            slidesOffsetBefore={-350}
            loop={true}
            autoplay={{
              delay: 1,  
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
              reverseDirection: true
            }}
            freeMode={false}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/" rel="noopener noreferrer">
                <video  className="sliderBgImage video" width="400" muted={isMuted} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleVideoClick} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Educators’ Leadership Retreat (EdLR)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Empower educators with advanced training in leadership, innovative pedagogy, and global educational practices.
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/"  rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    OxfordMUN
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Participate in high-level diplomatic simulations, fostering debate, diplomacy, and leadership skills.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">

              <a href="/CrafttheFutureChallenge24"  rel="noopener noreferrer">
                    <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                      <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Craft the Future Challenge

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Design innovative AI-enhanced kitchens, showcasing your creativity and problem-solving abilities.
                    </p>
                  </div>
                  <a href="/CrafttheFutureChallenge24" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>






            <SwiperSlide>
              <div className="sliderOne">
              <a href="/"  rel="noopener noreferrer">
                <video  className="sliderBgImage video" width="400" muted={isMuted} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleVideoClick} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Educators’ Leadership Retreat (EdLR)
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Empower educators with advanced training in leadership, innovative pedagogy, and global educational practices.
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/" rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    OxfordMUN
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Participate in high-level diplomatic simulations, fostering debate, diplomacy, and leadership skills.
                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/CrafttheFutureChallenge24" rel="noopener noreferrer">
                    <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                      <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">
                    Craft the Future Challenge

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Design innovative AI-enhanced kitchens, showcasing your creativity and problem-solving abilities.
                    </p>
                  </div>
                  <a href="CrafttheFutureChallenge24" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>



            

            {/* <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1719826604/videos/new_vid/ahmn5hzi4huwxv6mmt4w.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                       Lorem Ipsum is simply dummy text of the printing and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1719826602/videos/new_vid/fuknmj9nyib0hy1armtk.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>

          <Swiper
            breakpoints={{
              1440: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              768: {
                spaceBetween: 30,
                slidesPerView: 3,
              },
              200: {
                spaceBetween: 20,
                slidesPerView: 1,
              }
            }}
            // install Swiper modules
            modules={[Navigation, Autoplay, A11y]}
            spaceBetween={30}
            slidesPerView={3}
            speed={5000}
            centerInsufficientSlides={true}
            navigation={false}
            loop={true}            
            // autoplay={{
            //   delay: 2000,
            // }}
            autoplay={{
              delay: 1,
              disableOnInteraction: true,
              pauseOnMouseEnter: true
            }}
            freeMode={false} 
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="sliderOne">
                  <a href="/Residential_program"  rel="noopener noreferrer">
                    <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                      <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
                  </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Residential Program
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                    Immerse yourself in a structured learning environment, enhancing both academic and personal development.
                    </p>
                  </div>
                  <a href="/Residential_program" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/OxfordMUNPreChallenge"  rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Oxford Pre-Challenge

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Participate in an exciting challenge to sharpen your debating and diplomatic skills, with the winner getting their entire registration fee refunded.

                    </p>
                  </div>
                  <a href="OxfordMUNPreChallenge" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/"  rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">MU20 Fellowship

                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Experience a transformative journey that blends leadership training with real-world challenges and mentorship.

                    </p>
                  </div>
                  <a href="/" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>







            <SwiperSlide>
              <div className="sliderOne">
              <a href="/Residential_program"  rel="noopener noreferrer">
                    <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                      <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                      <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                    </video>
                  </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Residential Program
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">
                      Immerse yourself in a structured learning environment, enhancing both academic and personal development.
                    </p>
                  </div>
                  <a href="/Residential_program" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
              <a href="/OxfordMUNPreChallenge"  rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Oxford Pre-Challenge
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Participate in an exciting challenge to sharpen your debating and diplomatic skills, with the winner getting their entire registration fee refunded.
                    </p>
                  </div>
                  <a href="OxfordMUNPreChallenge" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="sliderOne">
                 <a href="/"  rel="noopener noreferrer">
                <video className="sliderBgImage video" width="400" muted  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
              </a>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">MU20 Fellowship
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Experience a transformative journey that blends leadership training with real-world challenges and mentorship.
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="sliderOne">

                <video className="sliderBgImage" width="400" muted >
                  <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1719826601/videos/new_vid/pkj4sa2upeup1eidydza.mp4" type="video/mp4" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sliderOne">
                <video className="sliderBgImage" width="400" muted>
                  <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1719826601/videos/new_vid/pkj4sa2upeup1eidydza.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>
                <div className="programContent">
                  <div className="programContent-inner">
                    <h6 className="textUpper lineHeight1">Find Your Voice</h6>
                    <h5 className="fs-32 fw-700 pb-10 textWhite lineHeight1">Leadership and Adventure Bootcamp
                    </h5>
                    <p className="fs-14 fw-400 textWhite ">Lorem Ipsum is simply dummy text of the printing
                      and typesetting
                    </p>
                  </div>
                  <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>

        </div>
      </section >

      <section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Our Partner</h6>
            <h2 className="fs-80 fw-600 textWhite">Network Schools</h2>
            <p className="subContent">In recognition of the challenges in creating abundant opportunities at scale,
              our vision is to curate these transformative experiences for a select network of schools that
              share our ideology for creating exceptional opportunities for high school students.</p>
          </div>
          <div className="networkSchoolSlider-outer">
            <Swiper
              modules={[Navigation, Autoplay, A11y]}
              spaceBetween={30}
              slidesPerView={'auto'}
               cssMode={true}              
              centerInsufficientSlides={false}
              navigation={false}
              loop={true}            
              autoplay={{
                delay: 1000,  // Set delay to 0 to start autoplay immediately
                disableOnInteraction: true,  // Disable autoplay when user interacts with slides
                pauseOnMouseEnter: true  // Pause autoplay on mouse enter
              }}
              freeMode={true} 
              centeredSlides={false}
            >
              

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/always.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ashoka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bharati.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bihani.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/billabong.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bk_birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/crossroads.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dalimess.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhi.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhiworld.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dolphin.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon_girl.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/duty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/emrald.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/goenka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/golden.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/him.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ies.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/inventure.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jayshree.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jbcn.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jindal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jodhamal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jyoti.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kasiga.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kunska.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/macro.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mann.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mcd.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/no.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pacific.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pathways.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pinegrove.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/queen.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar_college.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmas.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmata.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rockwoods.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sagar_school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sanskar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satpuda.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satyasai.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/scindia.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/shishukunj.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/singapoor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/strength.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/timor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/truth.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/uwc.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/valistus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vantage.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vidhya.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/virtus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vivek.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/wisdom.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/witty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/world.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/zabar.png" alt="networkSch" />
                </div>
              </SwiperSlide>




            </Swiper>
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack   pastProgVideoSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Our Video</h6>
            <h2 className="fs-80 fw-600 textWhite">Past Program Videos</h2>
          </div>
        </div>

        <Swiper 
              // breakpoints={{
              //   1440: {
              //     spaceBetween: 30,
              //     slidesPerView: 4,
              //   },
              //   768: {
              //     spaceBetween: 30,
              //     slidesPerView: 2,
              //   },
              //   200: {
              //     spaceBetween: 15,
              //     slidesPerView: 1,
              //   }
              // }}




           modules={[Pagination, A11y, Autoplay]}
           spaceBetween={30}
           slidesPerView={5}
            speed={1000}
           slidesOffsetBefore={-200}
          //  pagination={{ clickable: true }}
           loop={true}  // Uncomment this line if you want the slides to loop
           freeMode={true}
           autoplay={{
             delay: 1500,
             disableOnInteraction: false,  
             pauseOnMouseEnter: true
           }}
           centeredSlides={true}
           initialSlide={3} 

            breakpoints={{
        // when window width is >= 200px
        200: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        // when window width is >= 1440px
        1440: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
         
        >
        <SwiperSlide className='pastProgram'>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video " muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>

              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video "  
                      muted
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleVideoClick}
                      playsInline
                      >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>

           
              {showIframe && (
                      <div className='iframe_outer'>
                        <iframe
                          width="100%"
                          height="713px"
                          src="https://www.youtube.com/embed/NWeAlnmTfq8?si=vaod5vw-rd6mFpC6" title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                    </div>
                  )}
                 


              
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto readMoreIconBtn"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" 
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleReadMoreClick} /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video"  
                      muted
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={handleVideoClick}
                      playsInline
                      >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>

           
              {showIframe && (
                      <div className='iframe_outer'>
                        <iframe
                          width="100%"
                          height="713px"
                          src="https://www.youtube.com/embed/cjuBSu2Qtk0?si=l_HjW19PpXiBfoKr"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                    </div>
                  )}
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>







          <SwiperSlide className='pastProgram'>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video " muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="pastProgVideos  ">
              <h4 className="fs-25 fw-600 textUpper textWhite pb-20">Building the world</h4>
              <video className="progVideos video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
              </video>
              {/* <img src="assets/images/homeImage/pastProgActive.png" alt="networkSch" /> */}
              <div className="progVedioContent">
                <p className="fs-25 fw-400">In recognition of the challenges in creating abundant opportunities at
                  scale, our vision is to</p>
                <a href="#" className="readMoreIconBtn"><img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
              </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

      </section>

      <section className="p-80-20 bglightBlack skillSec">
        <div className="topHeading">
          <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">Why choose me</h6>
          <h2 className="fs-80 fw-600 textWhite pb-20">Skills You Learn</h2>
          {/* <p className="subContent textWhite">Building the worlds best marketing Your trusted partner for strategy,
            design, and dev</p> */}
        </div>
        <div className="skills-outer">
          <div className="skills">
            <img src="assets/images/homeImage/skillImg.png" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20">Mastery Through Hands-On Learning facilitating <b> Technical Skills </b></h3>
            <ul className="skillList">
              <li className="fs-16 fw-400"> Immersive workshops using the latest technologies.</li>
              <li className="fs-16 fw-400"> Real-world applications for problem-solving.</li>
              <li className="fs-16 fw-400"> Practical experience with cutting-edge tools.</li>
            </ul>
          </div>
          <div className="skills">
            <img src="assets/images/homeImage/skillImg.png" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20">Building Effective Leaders and Teams through <b>Human Skills</b> </h3>
            <ul className="skillList">
              <li className="fs-16 fw-400"> Collaborative projects with global peers.</li>
              <li className="fs-16 fw-400">Leadership exercises to enhance communication.</li>
              <li className="fs-16 fw-400">Developing empathy and interpersonal skills.</li>
            </ul>
          </div>
          <div className="skills">
            <img src="assets/images/homeImage/skillImg.png" alt="skillImg" />
            <h3 className="fs-25 fw-600 pb-20">Innovate, Strategize, and Solve Complex Problems through <b>Conceptual Skills</b></h3>
            <ul className="skillList">
              <li className="fs-16 fw-400">Encouraging strategic thinking and innovation.</li>
              <li className="fs-16 fw-400"> Interdisciplinary approach to connect concepts.</li>
              <li className="fs-16 fw-400">Real-world challenges to foster critical thinking.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="p-80-20 bgBlack intreactionSec">
      <Swiper
        modules={[Autoplay, Navigation, A11y, EffectFade]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,  
          pauseOnMouseEnter: true
        }}
        centeredSlides={true}
        effect="fade"
        navigation={true}
      >
        <SwiperSlide>
          <div className="intreactionVideo">
            <video className='video' muted playsInline>
              <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
              <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
            </video>
            <div className="intreactionInner">
              <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
              <p className="fs-34 fw-700 textWhite">Student Interactions</p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="intreactionVideo">
            <video className='video' muted playsInline>
              <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
              <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
            </video>
            <div className="intreactionInner">
              <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
              <p className="fs-34 fw-700 textWhite">Student </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>


      {/* <section className="p-80-20  bgBlack intreactionSec">
        <img src="assets/images/reviewImg.png" alt="home-img" />
        <div className="intreactionInner">
          <h2 className="fs-80 fw-700 textWhite">250K+ </h2>
          <p className="fs-34 fw-700 textWhite">Student Interactions</p>
        </div>
      </section> */}


      <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">clients</h6>
            <h2 className="fs-80 fw-600 textWhite">Testimonials</h2>
          </div>

          <Swiper
              breakpoints={{
                1440: {
                  spaceBetween: 40,
                  slidesPerView: 4,
                  centeredSlides: false,
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                },
                200: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                },
              }}
              modules={[Autoplay, A11y]}
              spaceBetween={50}
              slidesPerView={5}
              loop={true}
              autoplay={{
             delay: 1500,
             disableOnInteraction: false,  
             pauseOnMouseEnter: true
           }}
              centeredSlides={true}
          >
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>

                

              

                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />

                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            
        <SwiperSlide>

                <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>

                    

                  <div className="textWrapper">
                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                    <h6 className="titleWatch">Watch Story</h6>
                  </div>
                </div>

        </SwiperSlide>



            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>

               

                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline >
                  <source src="https://muVid.b-cdn.net/labmainpage.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>

               


                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonialImg">

                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://muVid.b-cdn.net/mainbannerceikt8.mp4" type="video/mp4" />
                  <source src="assets/images/homeImage/fecProgram3.png" type="video/ogg" />
                </video>


               
                {/* <img src="assets/images/testimonial2.png" alt='home-img' /> */}
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      

      <section className="bgBlack partnerSec partnerHomeSec pt-0">
        <div className="container-full">
          <div className="topHeading">
            <h6 className="fs-22 fw-600 textOrg pb-10 textUpper">community</h6>
            <h2 className="fs-80 fw-600 textWhite">Partners</h2>
          </div>
              
        <div className='networkSchoolSlider-outer'>

            <Swiper
              modules={[Navigation, Autoplay, A11y]}
              spaceBetween={30}
              slidesPerView={'auto'}
               cssMode={true}              
              centerInsufficientSlides={false}
              navigation={false}
              loop={true}            
              autoplay={{
                delay: 1000,  // Set delay to 0 to start autoplay immediately
                disableOnInteraction: true,  // Disable autoplay when user interacts with slides
                pauseOnMouseEnter: true  // Pause autoplay on mouse enter
              }}
              freeMode={true} 
              centeredSlides={false}
            >
                <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/acropolis.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/afs.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/allen.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/amity.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/boardng_school.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/chedgemaker.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/dcbs.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/decathelon.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/globe.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/flam_U.svg" alt="networkSch" />
                    </div>
                  </SwiperSlide>





                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/harvard.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/iip.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/iist.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/amity.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/inifd.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/ips.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                

                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/Josh_talk.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/joshtalk.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/malwanchal.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>




                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/map.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/motorad.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/nmims.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/oxford.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/prestige.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/redbull.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/sharks.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/shereenward.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/startupindia.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="networkSchoolSlider">
                      <img src="assets/images/partners_logos/symbiosis.png" alt="networkSch" />
                    </div>
                  </SwiperSlide>
              </Swiper>


        </div>



        </div>
      </section>

      <Footer />  

    </>
  )
}

export default HomeNew