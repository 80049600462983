// src/formdata.js
import React from 'react';
import DataTable from 'react-data-table-component';

// Define your columns and data here
const columns = [
  {
    name: 'School Name',
    selector: row => row.SchoolName,
    sortable: true,
  },
  {
    name: 'School Location',
    selector: row => row.SchoolLocation,
    sortable: true,
  },
  {
    name: 'Age',
    selector: row => row.age,
    sortable: true,
  },
  {
    name: 'Contact Number',
    selector: row => row.Contactnumber,
    sortable: true,
  },
  {
    name: 'Email',
    selector: row => row.email,
    sortable: true,
  },
  {
    name: 'Submit Date',
    selector: row => row.submitDate,
    sortable: true,
  },
  {
    name: 'Message',
    selector: row => row.Message,
    sortable: true,
  },
  {
    name: 'Page',
    selector: row => row.page,
    sortable: true,
  },
];

const data = [
  {
    id: 1,
    SchoolName: 'Tanishq',
    SchoolLocation: 'Indore',
    age: 24,
    Contactnumber: '9876543210',
    email: 'tanishq@example.com',
    submitDate: '2024-08-07T11:00:00Z',
    Message: 'Testing',
    page: 'Bootcamp Page',
  },
  {
    id: 2,
    SchoolName: 'Ashwin ',
    SchoolLocation: 'Maheshwar',
    age: 14,
    Contactnumber: '9876543211',
    email: 'ashwin@example.com',
    submitDate: '2024-08-07T11:30:00Z',
    Message: 'Sample message',
    page: 'summits Page',
  },
  // Add more data here with the new fields
];

const FormData = () => {
  return (
    <div>
      <DataTable
        title="Form Data"
        columns={columns}
        data={data}
        pagination
        highlightOnHover
      />
    </div>
  );
};

export default FormData;
