import React, { useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";

function Pillar() {
    const [isMuted, setIsMuted] = useState(true);
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
    };

    return (
        <>
            <Header />
            <section className="oxfordBanner">
                {/* <div style="padding-bottom: 56.25%; max-width: 100%; position: relative;"><iframe
                    src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0"
                    width="800px" height="450px" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"
                    frameborder="0"></iframe></div>   */}

                <div>
                    <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false}>
                        {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
                        <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1711693095/MU_New_Site/iv3tdgjgarbau4qlm7h4.mp4" type="video/mp4" />
                    </video>
                </div>


                <div className="banner-content">
                    <h1>Pillar Page</h1>
                    <div className={isActive ? "mute" : "unmute"}>
                        <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/unmute.svg" alt="unMuteIcon" />
                            <img className="muteIcon" src="/assets/images/mute.svg" alt="MuteIcon" /></a>
                    </div>
                </div>
            </section>

            <section className="boderGradient bgBlack p-80-20 pillarBoxSec">
                <div className="container pillarBox-outer">
                    <div className="pillarBox-innerLeft">
                        <div className="pillarBox ">
                            <img className="leftImg" src="assets/images/pillarPage/EngagePill.png" alt="pillarImg" />
                            <div className="pillarBoxContent">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Inspire</h4>
                                <p className="fs-16 fw-400 textWhite">Our summits are designed to ignite the spark of inspiration in every participant. Through engaging sessions, keynote speeches, and dynamic workshops, we bring together thought leaders and change-makers to share their stories and insights. Our goal is to inspire students to dream big, think creatively, and pursue their passions with unwavering determination.
                                </p>
                            </div>
                        </div>
                        <div className="pillarBox m-0">
                            <div className="pillarBoxContent">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Innovate
                                </h4>
                                <p className="fs-16 fw-400 textWhite">Innovation is at the heart of our summits. We provide a platform where students can explore cutting-edge ideas, develop innovative solutions, and challenge the status quo. With a focus on hands-on learning and real-world applications, our summits encourage participants to push boundaries and think outside the box, fostering a culture of creativity and progress.</p>
                            </div>
                            <img className="rightImg" src="assets/images/pillarPage/EnrichPill.png" alt="pillarImg" />
                        </div>
                    </div>
                    <div className="pillarBox-innerRight">
                        <div className="pillarBox m-0">
                            <img className="topImg" src="assets/images/pillarPage/EmpowerPill.png" alt="pillarImg" />
                            <div className="pillarBoxContent">
                                <h4 className="fs-34 fw-600 pb-20 textOrg ">Impact
                                </h4>
                                <p className="fs-16 fw-400 textWhite">Our summits aim to create a lasting impact on both individuals and communities. By addressing pressing global issues and promoting actionable solutions, we empower students to become agents of change. Through collaborative projects, policy simulations, and social initiatives, participants gain the skills and knowledge to make a meaningful difference in the world.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="programsSec bglightBlack  p-80-20">
                <div className="container">
                    <h2 className="fs-80 fw-600 textWhite">Programs</h2>
                    <div className="program-outer">
                        <div className="program-innerLeft">
                            <img src="assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">Leadership and Adventure Bootcamp</h3>
                            <p className="fs-16 fw-400 textWhite pb-20">By connecting students from various backgrounds and schools, our
                                flagship programmes engage students and actively involve them in the learning and development
                                process.</p>
                            <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue</span>House of Lalpur</h4>
                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Location.svg" alt="Location" />
                                        Location<span className="fs-18 fw-400">Indore</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                                        Certification<span className="fs-18 fw-400">We Provide</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                                        Duration<span className="fs-18 fw-400">2 days </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Reidential<span className="fs-18 fw-400">Team-building exercises...</span></h6>
                                </li>
                            </ul>
                            <a href="#" className="registerBtn">Join now<img className="widthAuto"
                                src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            <a href="#" className="readMoreBtn">Read More</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bgBlack testimonialSec testimonialHomeSec">
                <div className="container-full">
                    <div className="topHeading">
                        <h2 className="fs-80 fw-600 textWhite">Testimonials</h2>
                    </div>

                    <Swiper breakpoints={{
                        1440: {
                            spaceBetween: 50,
                            slidesPerView: 4,
                            centeredSlides: false,
                        },
                        768: {
                            spaceBetween: 30,
                            slidesPerView: 3,

                        },
                        200: {
                            spaceBetween: 20,
                            slidesPerView: 1,
                        }
                    }}
                        modules={[Autoplay, A11y]}
                        spaceBetween={50}
                        slidesPerView={5}
                        loop={true}
                        autoplay={false}
                        centeredSlides={true}
                    >
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <video className="testimonialVideo " controls muted>
                                    <source src="assets/videos/video1.mov" type="video/mp4" />
                                </video>
                                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />

                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <video className="testimonialVideo " controls muted>
                                    <source src="assets/videos/video2.mov" type="video/mp4" />
                                </video>
                                {/* <img src="assets/images/testimonial2.png" alt='home-img' /> */}
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <video className="testimonialVideo " controls muted>
                                    <source src="assets/videos/video3.mov" type="video/mp4" />
                                </video>
                                {/* <img src="assets/images/testimonial3.png" alt='home-img' /> */}
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <video className="testimonialVideo " controls muted>
                                    <source src="assets/videos/video1.mov" type="video/mp4" />
                                </video>
                                {/* <img src="assets/images/testimonial1.png" alt='home-img' /> */}
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="testimonialImg">
                                <video className="testimonialVideo " controls muted>
                                    <source src="assets/videos/video2.mov" type="video/mp4" />
                                </video>
                                {/* <img src="assets/images/testimonial2.png" alt='home-img' /> */}
                                <div className="textWrapper">
                                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                                    <h6 className="titleWatch">Watch Story</h6>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="p-80-20 bglightBlack networkSchoolSec">
                <div className="container">
                    <div className="topHeading">
                        <h2 className="fs-80 fw-600 textWhite">Participating Schools</h2>
                    </div>
                    <div className="networkSchoolSlider-outer">
                        <Swiper
                            breakpoints={{
                                1440: {
                                    spaceBetween: 30,
                                    slidesPerView: 4
                                },
                                768: {
                                    spaceBetween: 30,
                                    slidesPerView: 3,
                                },
                                200: {
                                    spaceBetween: 20,
                                    slidesPerView: 1,
                                }
                            }}
                            // install Swiper modules
                            modules={[Navigation, Pagination, Autoplay, A11y]}
                            spaceBetween={30}
                            slidesPerView={4}
                            navigation={false}
                            loop={true}
                            autoplay={false}
                            centeredSlides={false}
                            pagination={{ clickable: true }}
                        >
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch1.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch2.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch3.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch4.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="networkSchoolSlider">
                                    <img src="assets/images/homeImage/networkSch4.svg" alt="networkSch" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <section className=" bglightBlack  p-80-20 opportunitiesSec">
                <div className="container opportunities-outer">
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper purpleGradient">
                            <img src="assets/images/pillarPage/opportunity1.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">One of a kind learning opportunities.</h6>
                                <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            </div>
                        </div>
                        <div className="opportunities-wrapper yellowGradient">
                            <img src="assets/images/pillarPage/opportunity2.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">One of a kind learning opportunities.</h6>
                                <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper orgGradient">
                            <img src="assets/images/pillarPage/opportunity3.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">One of a kind learning opportunities.</h6>
                                <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            </div>
                        </div>
                        <div className="opportunities-wrapper blueGradient">
                            <img src="assets/images/pillarPage/opportunity4.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">One of a kind learning opportunities.</h6>
                                <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="opportunities-inner">
                        <div className="opportunities-wrapper redGradient">
                            <img src="assets/images/pillarPage/opportunity5.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite pb-10">One of a kind learning opportunities.</h6>
                                <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            </div>
                        </div>
                        <div className="opportunities-wrapper greenGradient">
                            <img src="assets/images/pillarPage/opportunity6.png" alt="opportunitiesImg" />
                            <div className="contentWrapper">
                                <h6 className="fs-32 fw-600 textWhite  pb-10">One of a kind learning opportunities.</h6>
                                <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="callToAction bgBlack callToActionPillar ">
                <div className="container  ">
                    <div className="  callToAction-inner">
                        <div className="row">
                            <div className="column callToActionLeft">
                                <h5>Join us for a <br />Great experience</h5>
                                <p>Register your school today to the Oxford MUN India 2024.</p>
                            </div>
                            <div className="column callToActionRight">
                                <p>Visit below link to secure your spot and embark on an unforgettable journey of discovery, diplomacy, and development.
                                </p>
                                <a href="#" className="registerBtn">Join now<img className="widthAuto"
                                    src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /></a>
                                <a href="#" className="readMoreBtn">Read More</a>
                                {/* <a href="#" className="registerBtn"> Register Now </a>  */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="advenBootcampSec p-80-20 bgBlack pt-0">
                <div className="container">
                    <div className="advenBootcamp-outer">
                        <div className="advenBootcamp-innerLeft">
                            <img src="assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>
                        <div className="advenBootcamp-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">Leadership and Adventure Bootcamp</h3>
                            <p className="fs-16 fw-400 textWhite pb-20">By connecting students from various backgrounds and schools, our
                                flagship programmes engage students and actively involve them in the learning and development
                                process.</p>

                            <ul className="advenBootcampList">
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default Pillar