import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './footer.css'
export class Footer extends Component {
    static propTypes = {

    }

    render() {
        return (
            <div>
                <footer>
                    <div className="container">
                        <div className="main-footer">
                            <div className="logo-footer">
                                <div className="site-logo"><a href="/"><img src="/assets/images/whitelogo@4x.png" alt="home-img" /></a></div>
                                <div className="footer-content">
                                    <p>Empowering high school students with co-curricular programs that inspire leadership, creativity, and growth.</p>
                                </div>
                                <div className="social-icon">
                                    <ul className="social-nav">
                                        <li className="social-item"><a href="https://www.facebook.com/MU20schoolofopportunity/"><i className="fa fa-facebook-f"></i></a></li>
                                        {/* <li className="social-item"><a href="#"> <i className="fa fa-twitter"></i></a></li> */}
                                        <li className="social-item"><a href="https://www.instagram.com/mu20.world?igsh=dHRyeDhpZ2RxY2lz" target="_blank"> <i className="fa fa-instagram"></i></a></li>
                                        <li className="social-item"><a href="https://www.linkedin.com/company/mu20/"> <i className="fa fa-linkedin"></i> </a></li>
                                    </ul>
                                </div>

                            </div>
                            <div className="programs-footer">
                                <div className="head-title">
                                    <h4>Programs Categories</h4>
                                </div>
                                <div className="divider"></div>
                                <ul className="footer-nav">
                                    <li className="footer-item"><a href="/bootcamp_pillar" className="footer-link"> Bootcamps</a></li>
                                    <li className="footer-item"><a href="/Residential_program" className="footer-link">Residential Program</a></li>
                                    <li className="footer-item"><a href="/summits" className="footer-link">Summits</a></li>
                                    <li className="footer-item"><a href="/online_challenges" className="footer-link">Online Challenges</a></li>
                                </ul>

                            </div>
                            {/* <div className="about-footer">
                                <div className="head-title">
                                    <h4>About mu20</h4>
                                </div>
                                <div className="divider"></div>
                                <ul className="footer-nav">
                                    <li className="footer-item"><a href="/" className="footer-link"> About</a></li>
                                    <li className="footer-item"><a href="/networkSchool" className="footer-link">Network School</a></li>
                                  
                                </ul>
                            </div> */}
                            <div className="feature-footer">
                                <div className="head-title">
                                    <h4>Featured Programs</h4>
                                </div>
                                <div className="divider"></div>
                                <ul className="footer-nav">
                                <li className="footer-item"><a href="/oxfordmun" className="footer-link">OxfordMUN India 24</a></li>
                                    <li className="footer-item"><a href="/MU20OpportunitySummit24" className="footer-link"> MU20 Opportunity Summit 24</a></li>
                                    <li className="footer-item"><a href="/LeadershipandAdventureBootcamp" className="footer-link">Leadership and Adventure Bootcamp</a></li>
                                    <li className="footer-item"><a href="/Young_Leaders_Bootcamp" className="footer-link">Young Leaders Bootcamp</a></li>
                                    <li className="footer-item"><a href="/CrafttheFutureChallenge24" className="footer-link">Craft the Future Challenge 24</a></li>
                                    <li className="footer-item"><a href="/OxfordMUNPreChallenge" className="footer-link">Oxford MUN Pre-Challenge</a></li>
                                  

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="container">
                            <div className="inner-footer">
                                <div className="copyright">
                                    <p>Copyright ©️ 2024 MU20 School of Opportunity All rights reserved</p>
                                </div>
                                <div className="terms">
                                    <ul className="terms-nav">
                                        <li className="terms-item"><a href="#" className="terms-link">Privacy Policy</a></li>
                                        <li className="terms-item"><a href="#" className="terms-link"> Terms &amp; Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer
