
// Header.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './header.css';
import { FlapNav } from 'flap-nav';
import axios from 'axios';
import Popup from 'reactjs-popup';
import PopupForm from '../PopupForm';

const data = require('../../../data.json');

const Header = () => {
    const [scrolltopdata, setscrolltopdata] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 15) {
                setscrolltopdata('');
            } else {
                setscrolltopdata('scrolled');
            }
        });
    }, []);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <header>  
            <div className={`main-header ${scrolltopdata}`}>
                <div className="site-logo">
                    <a title="" href="/"><img src="/assets/images/whitelogo@4x.png" alt="Logo" /></a>
                </div>

               

                <div className="navbar">
                <FlapNav data={data} />
                
                <div className="header-btn">    
                        <button className="registerBtn headerBtn" onClick={togglePopup}> Enquire Now </button>
                </div>  
                   
                    
                </div>

                {showPopup && (
                    <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                        <PopupForm togglePopup={togglePopup} /> {/* Use the PopupForm component */}
                    </Popup>
                )}
            </div>




            {/* <section className="menu_header_bg mobile_menu">
                <div className="meu_part">
                    <div className="menu_logo">   
                        <img src="/assets/images/whitelogo@4x.png" alt="Logo"/>
                    </div>

                    <ul className="menu_items">
                        <li><a href="" className='menu_link'>Home</a></li>
                        <li><a href="" className='menu_link'>Online Challenges</a></li>
                        <li><a href=""  className='menu_link'>Residential Program</a></li>
                        <li><a href=""  className='menu_link'>Bootcamp</a></li>
                        <li><a href=""  className='menu_link'>Summits</a></li>
                    </ul>
                </div>
    </section> */}
   <button className="position-relative menu_toggle brgr_btn " onClick={toggleMenu} aria-label="Toggle menu">
   <i className="fi fi-list mobile-flap-nav-toggle"></i>
            </button>
            <section className={`menu_header_bg ${isOpen ? 'show' : ''}`}>
                <button className="menu_close close_btn" onClick={toggleMenu} aria-label="Close menu">
                <i className="fi mobile-flap-nav-toggle fi-x"></i>
                </button>
                <div className="meu_part">
                    <div className="menu_logo">
                        <img src="/assets/images/whitelogo@4x.png" alt="Logo"/>
                    </div>
                    <ul className="menu_items">
                        <li><a href="/" className='menu_link'>Home</a></li>
                        <li><a href="/Online_Challenges" className='menu_link'>Online Challenges</a></li>
                        <li><a href="/Residential_program" className='menu_link'>Residential Program</a></li>
                        <li><a href="/Bootcamp_pillar" className='menu_link'>Bootcamp</a></li>
                        <li><a href="/Summits" className='menu_link'>Summits</a></li>
                    </ul>
                </div>
            </section>





        </header>
    );
};

export default Header;
