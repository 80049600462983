import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { Helmet } from 'react-helmet';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// import PureModal from 'react-pure-modal';
// import 'react-pure-modal/dist/react-pure-modal.min.css';

// ----------------Swiper slider import------------------
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/effect-fade";
import Popup from 'reactjs-popup';
import PopupForm from './PopupForm'; // Adjust the path as per your project structure
import { keyboard } from '@testing-library/user-event/dist/keyboard';



import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles




function OnlineChallenges() {

  //Code fore Scroll to Section 
  const scrollToSection = () => {
    const section = document.getElementById('programs_s');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };



  //Code for Iframe //

  const [showIframeLeft, setShowIframeLeft] = useState(false);
  const [showIframeRight, setShowIframeRight] = useState(false);

  const handleIconClickLeft = (e) => {
    e.preventDefault();
    setShowIframeLeft(true);
  };

  const handleIconClickRight = (e) => {
    e.preventDefault();
    setShowIframeRight(true);
  };

  const handleIframeCloseLeft = () => {
    setShowIframeLeft(false);
  };

  const handleIframeCloseRight = () => {
    setShowIframeRight(false);
  };


  // END //





  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };







  // const handleMouseEnter = (event) => {
  //     event.target.play();
  //   };

  //   const handleMouseLeave = (event) => {
  //     event.target.pause();
  //   };

  const [isMuted, setIsMuted] = useState(true);
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };

  //Video on Hover Start


  const [showIframe, setShowIframe] = useState(false);
  const [playingVideos, setPlayingVideos] = useState(new Set());

  // Replace with actual logic to determine if the device is mobile or touch
  // Use window.matchMedia to determine if the viewport is mobile-sized
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  useEffect(() => {

    AOS.init({
      duration: 1000, // Duration of the animation (in milliseconds)
    });


    if (isMobile) {
      const videos = document.querySelectorAll('.video');
      const observerOptions = {
        root: null, // Use the viewport as the container
        rootMargin: '0px',
        threshold: 0.5, // Adjust this as needed
      };

      const handleIntersection = (entries) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            if (video.paused) {
              // Attempt to play the video and handle cases where play() might not return a Promise
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch(error => console.error('Play interrupted:', error));
              }
              setPlayingVideos(prev => new Set(prev.add(video)));
            }
          } else {
            if (!video.paused) {
              // Attempt to pause the video and handle cases where pause() might not return a Promise
              const pausePromise = video.pause();
              if (pausePromise !== undefined) {
                pausePromise.catch(error => console.error('Pause interrupted:', error));
              }
              setPlayingVideos(prev => {
                const updated = new Set(prev);
                updated.delete(video);
                return updated;
              });
            }
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, observerOptions);
      videos.forEach(video => {
        observer.observe(video);
      });

      return () => {
        videos.forEach(video => {
          observer.unobserve(video);
        });
      };
    }
  }, [isMobile, playingVideos]);

  const handleMouseEnter = (e) => {
    if (!isMobile) {
      const video = e.target;
      video.muted = true; // Keep the video muted to avoid autoplay restrictions
      if (video.paused) {
        // Attempt to play the video and handle cases where play() might not return a Promise
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise.catch(error => console.error('Play interrupted:', error));
        }
      }
    }
  };

  const handleMouseLeave = (e) => {
    if (!isMobile) {
      const video = e.target;
      if (!video.paused) {
        // Attempt to pause the video and handle cases where pause() might not return a Promise
        const pausePromise = video.pause();
        if (pausePromise !== undefined) {
          pausePromise.catch(error => console.error('Pause interrupted:', error));
        }
      }
    }
  };




  //Video on Hover End

  return (
    <>
      <Helmet>
        <title>MU20 Online Challenges   </title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Header />
      <section className="verticalVideo oxfordBanner pillars_banner">
        {/* <div style="padding-bottom: 56.25%; max-width: 100%; position: relative;"><iframe
                    src="https://player.vimeo.com/video/347119375?autoplay=1&loop=1&muted=1&title=0&portrait=0&byline=0&keyboard=0"
                    width="800px" height="450px" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"
                    frameborder="0"></iframe></div>   */}

        <div>
          {/* <video className="sliderBgImage" autoPlay loop playsinline width="100%" muted={isMuted ? true : false}>
                        
                        <source src="https://res.cloudinary.com/dpj2oayjr/video/upload/v1711693095/MU_New_Site/iv3tdgjgarbau4qlm7h4.mp4" type="video/mp4" />
                    </video> */}
          <video className="sliderBgImage" autoPlay loop playsInline width="100%" muted={isMuted ? true : false} playsinline>
            {/* <source src="assets/videos/OxfordVideo-Com.mp4" type="video/mp4" /> */}
            <source src="https://musite.b-cdn.net/onlinechallengepillar.mp4" type="video/mp4" />
          </video>
        </div>


        <div className="banner-content">




          <div className='page_Heading'>
            <h1 data-aos="fade-up">ONLINE CHALLENGES</h1>
            <p className='heading_cont' data-aos="fade-up">Challenge yourself with our exciting online challenges. Compete, collaborate, and innovate from anywhere in the world, showcasing your talents on a global stage.</p>
          </div>

          <div className={isActive ? "mute" : "unmute"}>
            <a onClick={() => { setIsMuted(!isMuted); ToggleClass(); }} className="muteBtn widthAuto" id="btnUnMute"><img className="unmuteIcon" src="/assets/images/mute.svg" alt="unMuteIcon" />
              <img className="muteIcon" src="/assets/images/unmute.svg" alt="MuteIcon" /></a>
          </div>
        </div>
      </section>

      <section className="boderGradient bgBlack p-80-20 pillarBoxSec">
        <div className="container pillarBox-outer">
          <div className="pillarBox-innerLeft">
            <div className="pillarBox "  data-aos="fade-up">
              <div className="w-50">
                <img className="leftImg" src="assets/images/pillarimage/connect.jpg" alt="pillarImg" />
              </div>


              <div className="pillarBoxContent w-50">
                <h4 className="fs-34 fw-600 pb-20 textOrg " >Connect
                </h4>
                <p className="fs-16 fw-400 textWhite">Our online challenges unite participants worldwide via digital platforms, creating a diverse learning community of students and educators. These challenges foster meaningful connections, enabling participants to share insights, learn from each other, and form a network of like-minded individuals committed to making a positive impact on their communities and beyond.
                </p>
              </div>
            </div>
            <div className="pillarBox create m-0"  data-aos="fade-up">
              <div className="pillarBoxContent w-50">
                <h4 className="fs-34 fw-600 pb-20 textOrg ">Create
                </h4>
                <p className="fs-16 fw-400 textWhite">Creativity fuels our online challenges. Participants are encouraged to think innovatively, develop unique solutions, and showcase their talents. Through designing projects, crafting presentations, or brainstorming ideas, our platform fosters creative expression. This process empowers participants to transform their visions into reality, promoting problem-solving and inspiring contributions to their communities.</p>
              </div>
              <div className="w-50">
                <img className="rightImg" src="assets/images/pillarimage/create.jpg" alt="pillarImg" />
              </div>

            </div>
          </div>
          <div className="pillarBox-innerRight "  data-aos="fade-up">
            <div className="pillarBox collaborate m-0">
              <img className="topImg" src="assets/images/pillarimage/collaborate.jpg" alt="pillarImg" />

              <div className="pillarBoxContent">
                <h4 className="fs-34 fw-600 pb-20 textOrg ">Collaborate</h4>
                <p className="fs-16 fw-400 textWhite">Collaboration is central to our online challenges, emphasizing teamwork to achieve shared goals. Participants engage in group projects and tasks, learning the importance of diverse perspectives. This cooperative environment enhances the learning experience, promoting skill-building and preparing students for future endeavors. By working together, participants gain valuable insights into the power of collective effort in overcoming challenges.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="programsSec bglightBlack   p-80-20" id='programs_s'>
        <div className="container" >
          <h2 className="fs-80 fw-600 textWhite"  data-aos="fade-up">Programs</h2>
          <div className="program-outer" data-aos="fade-up">
            <div className="program-innerLeft">
              <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsinline>
                <source src="assets/videos/video1.mov" type="video/mp4" />
              </video>
            </div>
            <div className="program-innerRight"  >
              <h3 className="fs-34 fw-600 pb-20 textOrg ">OXFORDMUN INDIA- PRE CHALLENGE</h3>
              <p className="fs-16 fw-400 textWhite pb-20">The OxfordMUN Pre-Challenge is an innovative platform designed to identify and nurture the brightest young minds around the globe, focusing on diplomacy, public speaking, and policy-making. This challenge offers students an unparalleled opportunity to showcase their talents, expand their understanding of international relations, and tackle complex global issues. Participants will be tested on their ability to think on their feet, communicate effectively, and develop innovative solutions to real-world problems.

              </p>
              <h4 className="fs-18 pb-20 fw-400 textWhite"><span className="textOrg">Mode :</span>Online</h4>

              <a className="registerBtn" href='/OxfordMUNPreChallenge'>
                Explore <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
              </a>
              <button className="registerBtn joinbtn" onClick={togglePopup} >
                Enquire Now
              </button>

              {showPopup && (
                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                  <PopupForm togglePopup={togglePopup} />
                </Popup>
              )}




            </div>
          </div>



          <div className="program-outer" data-aos="fade-up">
            <div className="program-innerLeft">
              <video className="sliderBgImage" width="600" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsinline>
                <source src="https://musite.b-cdn.net/ctfmainpage.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="program-innerRight">
              <h3 className="fs-34 fw-600 pb-20 textOrg ">Craft the Future Challenge</h3>
              <p className="fs-16 fw-400 textWhite pb-20">The Craft the Future Challenge is an innovative online AI-driven kitchen designing competition powered by MU20 School of Opportunity and FLAME University. This challenge invites participants to explore the intersection of technology and creativity, utilizing artificial intelligence to design cutting-edge, functional kitchen spaces. By blending design principles with AI capabilities, the challenge aims to push the boundaries of what is possible in modern kitchen design, encouraging participants to craft visionary solutions that align with the needs of the future.

              </p>
              <h4 className="fs-18 pb-20 fw-400 textWhite"><span className="textOrg">Mode :</span> Online</h4>

              <a className="registerBtn" href='/CrafttheFutureChallenge24'>
                Explore <img className="widthAuto"
                  src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
              </a>
              <button className="registerBtn joinbtn" onClick={togglePopup} >
                Enquire Now
              </button>

              {showPopup && (
                <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                  <PopupForm togglePopup={togglePopup} />
                </Popup>
              )}




            </div>
          </div>
        </div>
      </section>

      {/* <section className="bgBlack testimonialSec testimonialHomeSec">
        <div className="container-full">
          <div className="topHeading">
         
            <h2 className="fs-80 fw-600 textWhite">Testimonials</h2>
          </div>

          <Swiper
              breakpoints={{
                1440: {
                  spaceBetween: 40,
                  slidesPerView: 4,
                  centeredSlides: false,
                },
                768: {
                  spaceBetween: 30,
                  slidesPerView: 3,
                },
                200: {
                  spaceBetween: 20,
                  slidesPerView: 1,
                  autoplay: false,

                },
              }}
              modules={[Autoplay, A11y]}
              spaceBetween={50}
              slidesPerView={5}
              loop={true}
              autoplay={{
             delay: 1500,
             disableOnInteraction: false,  
             pauseOnMouseEnter: true
           }}
              centeredSlides={true}
          >
           <SwiperSlide>

<div className="testimonialImg">
<video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
<source src="https://musite.b-cdn.net/LABwebtest1.mp4" type="video/mp4" />

</video>

  <div className="textWrapper">
    <img src="/assets/images/videoSec.svg" alt='home-img' />
    <h6 className="titleWatch">Watch Story</h6>
  </div>
</div>

</SwiperSlide>

   
        <SwiperSlide>

                <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/LABwebtest2.mp4" type="video/mp4" />
                
                </video>

                  <div className="textWrapper">
                    <img src="/assets/images/videoSec.svg" alt='home-img' />
                    <h6 className="titleWatch">Watch Story</h6>
                  </div>
                </div>

        </SwiperSlide>



            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/LABwebtest3.mp4" type="video/mp4" />
                 
                </video>

               

               
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>

<div className="testimonialImg">
<video className="testimonialVideo video" muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline controls>
<source src="https://musite.b-cdn.net/YLPwebtest1.mp4" type="video/mp4" />

</video>

  <div className="textWrapper">
    <img src="/assets/images/videoSec.svg" alt='home-img' />
    <h6 className="titleWatch">Watch Story</h6>
  </div>
</div>

</SwiperSlide>

    <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />
      


            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPwebtest2.mp4" type="video/mp4" />
                 
                </video>

              
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/YLPWebtest3.mp4" type="video/mp4" />
                 
                </video>

             
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="testimonialImg">
                <video className="testimonialVideo video" controls muted onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} playsInline>
                  <source src="https://musite.b-cdn.net/videos/summit_web_test.mp4" type="video/mp4" />
                 
                </video>

               
                <div className="textWrapper">
                  <img src="/assets/images/videoSec.svg" alt='home-img' />
                  <h6 className="titleWatch">Watch Story</h6>
                </div>
              </div>
            </SwiperSlide>

          
          </Swiper>
        </div>
      </section> */}



      <section className="p-80-20 bglightBlack networkSchoolSec">
        <div className="container">
          <div className="topHeading">
            <h2 className="fs-80 fw-600 textWhite"  data-aos="fade-up">Participating Schools</h2>

          </div>
          <div className="networkSchoolSlider-outer"  data-aos="fade-up">
            <Swiper
              modules={[Navigation, Autoplay, A11y, Keyboard]}  // Necessary modules
              spaceBetween={10}  // Slight space between slides for better appearance
              slidesPerView={7}
              cssMode={false}
              centerInsufficientSlides={true}
              navigation={false}
              loop={true}
              autoplay={{
                delay: 1500,  // Autoplay delay
                disableOnInteraction: true,  // Pause autoplay on user interaction
                pauseOnMouseEnter: true  // Pause autoplay on hover
              }}
              centeredSlides={true}
              keyboard={{
                enabled: true,
                onlyInViewport: true  // Enable keyboard navigation only when Swiper is in view
              }}

              breakpoints={{
                // when window width is >= 200px
                200: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                  autoplay: false,

                },
                380: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                  slidesOffsetBefore: -35,
                },


                // when window width is >= 767px (tablet portrait)
                768: {
                  slidesPerView: 7,
                  spaceBetween: 0,
                },
                // when window width is >= 1024px (tablet landscape)
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 0,
                },
                // when window width is >= 1440px (desktop)
                1440: {
                  slidesPerView: 7,
                  spaceBetween: 0,
                },
              }}




            >

              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/always.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ashoka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bharati.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bihani.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/billabong.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/bk_birla.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/crossroads.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dalimess.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhi.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/delhiworld.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/dolphin.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/doon_girl.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/duty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/emrald.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/goenka.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/golden.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/him.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/ies.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/inventure.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jayshree.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jbcn.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jindal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jodhamal.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/jyoti.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kasiga.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/kunska.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/macro.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mann.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/mcd.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/no.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pacific.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pathways.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/pinegrove.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/queen.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajkumar_college.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmas.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rajmata.png" alt="networkSch" />
                </div>
              </SwiperSlide>


              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/rockwoods.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sagar_school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/sanskar.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satpuda.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/satyasai.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/school.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/scindia.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/shishukunj.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/singapoor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/strength.png" alt="networkSch" />
                </div>
              </SwiperSlide>



              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/timor.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/truth.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/uwc.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/valistus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vantage.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vidhya.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/virtus.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/vivek.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/wisdom.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/witty.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/world.png" alt="networkSch" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="networkSchoolSlider">
                  <img src="assets/images/School_logos/zabar.png" alt="networkSch" />
                </div>
              </SwiperSlide>




            </Swiper>
          </div>
        </div>
      </section>

      <section className=" bglightBlack  p-80-20 opportunitiesSec">
        <div className="container opportunities-outer">
          <div className="opportunities-inner">
            <div className="opportunities-wrapper purpleGradient">
              <img src="assets/images/onlinechallenges_grid/1.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite pb-10">10,000+ Active Participants</h6>
                <p className="fs-16 fw-400 textWhite pb-20" >Engaging a vast network of over 10,000 students globally.</p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
            <div className="opportunities-wrapper yellowGradient">
              <img src="assets/images/onlinechallenges_grid/2.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite  pb-10" >5,000+ Innovative Projects

                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Showcasing creativity with over 5,000 project submissions.



                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
          </div>
          <div className="opportunities-inner">
            <div className="opportunities-wrapper orgGradient">
              <img src="assets/images/onlinechallenges_grid/3.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite pb-10">300+ Hours of Expert Training


                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Delivering more than 300 hours of comprehensive training sessions.


                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
            <div className="opportunities-wrapper blueGradient">
              <img src="assets/images/pillarimage/school.jpg" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite  pb-10">150+ Schools Involved


                </h6>
                <p className="fs-16 fw-400 textWhite pb-20"> Participation from a diverse range of over 150 schools worldwide.
                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
          </div>
          <div className="opportunities-inner">
            <div className="opportunities-wrapper redGradient">
              <img src="assets/images/onlinechallenges_grid/5.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite pb-10">12000+ Certificates Awarded



                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Recognizing excellence and participation with over 1,200 certificates.
                </p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
            <div className="opportunities-wrapper greenGradient">
              <img src="assets/images/onlinechallenges_grid/6.png" alt="opportunitiesImg" />
              <div className="contentWrapper" data-aos="fade-up">
                <h6 className="fs-32 fw-600 textWhite  pb-10">30+ Industry Collaborations
                </h6>
                <p className="fs-16 fw-400 textWhite pb-20">Partnering with more than 30 companies and organizations to enhance learning experiences.</p>
                {/* <a href="#" className="readMoreBtn p-0">Read More<img className="widthAuto"
                                    src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="callToAction bgBlack callToActionPillar ">
        <div className="container  " data-aos="fade-up">
          <div className="  callToAction-inner">
            <div className="row">
              <div className="column callToActionLeft">
                <h5>Join us for a <br />Great experience</h5>
                {/* <p>Register your school today to the Oxford MUN India 2024.</p> */}
              </div>
              <div className="column callToActionRight">
                <p>
                  Participate in our exciting Online Challenges and showcase your talents on a global stage. Visit the link below to secure your spot and embark on a journey of innovation and collaboration.
                </p>

                <button className="registerBtn" onClick={scrollToSection}>
                  Explore Now
                  {/* <img className="widthAuto" src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /> */}
                </button>

                {showPopup && (
                  <Popup open={showPopup} closeOnDocumentClick onClose={togglePopup} modal nested>
                    <PopupForm togglePopup={togglePopup} /> {/* Render the PopupForm component */}
                  </Popup>
                )}


                {/* <a href="#" className="registerBtn">Join now<img className="widthAuto"
                                    src="assets/images/pillarPage/ArrowOrg.svg" alt="iconBtn" /></a> */}
                {/* <a href="#" className="readMoreBtn">Read More</a> */}
                {/* <a href="#" className="registerBtn"> Register Now </a>  */}
              </div>
            </div>
          </div>

        </div>
      </section>


      {/* <section className="programsSec bgBlack  p-80-20   pt-0">


                <div className="container">
                <div className="program-outer">
                    
                <div className="program-innerLeft" style={{ position: 'relative' }}>
                  <video
                    className="sliderBgImage past_movie_vid"
                    width="600"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source src="assets/videos/video1.mov" type="video/mp4" />
                  </video>
                  <div className="video-caption" style={{ backgroundColor: "white" }}>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.</p>
                    <a href="#" className="past_movie" onClick={handleIconClickLeft}>
                      <img className="arrow" src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                    </a>
                  </div>
                  {showIframeLeft && (
                    <div className="iframe-container">
                      <iframe
                        src="https://www.youtube.com/embed/3ylVNxFkU6A?si=EZXARQzAdqqrnQWx&autoplay=1"
                        width="650"
                        height="400"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                      ></iframe>
                      <button onClick={handleIframeCloseLeft} className="close-button">Close</button>
                    </div>
                  )}
                </div>



                      <div className="program-innerRight" style={{ position: 'relative' }}>
                  <video
                    className="sliderBgImage past_movie_vid"
                    width="600"
                    muted
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    playsInline
                  >
                    <source src="assets/videos/video1.mov" type="video/mp4" />
                  </video>

                  <div className="video-caption" style={{ backgroundColor: "white" }}>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.</p>
                    <a href="#" className="past_movie" onClick={handleIconClickRight}>
                      <img className="arrow" src="assets/images/homeImage/arrowWhite.svg" alt="iconBtn" />
                    </a>
                  </div>
                  {showIframeRight && (
                    <div className="iframe-container_right">
                      <iframe
                        src="https://www.youtube.com/embed/3ylVNxFkU6A?si=EZXARQzAdqqrnQWx&autoplay=1"
                        width="650"
                        height="400"
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                      ></iframe>
                      <button onClick={handleIframeCloseRight} className="close-button">Close</button>
                    </div>
                  )}
                </div>
                </div>
                </div>
                
             </section> */}




      {/* <section className="programsSec bgBlack  p-80-20   pt-0">
                <div className="container">
                   
                    <div className="program-outer">
                        <div className="program-innerLeft">
                            <img src="assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>
                        <div className="program-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">MU20 High School Summit 2023</h3>
                            <p className="fs-16 fw-400 textWhite pb-20">Welcome to the MU20 Summit 2024, Asia's premier high school conference. This year, join over 4,000 brilliant minds from around the globe for an unforgettable journey of innovation, inspiration, and impact. Experience transformative challenges, visionary leaders, and groundbreaking ideas. Be part of the future of leadership at the MU20 Summit 2024. Don’t miss this epic celebration of creativity and collaboration.
                            </p>
                            <h4 className="fs-18 fw-400 textWhite"><span className="textOrg">Venue :</span>The Emerald Heights International School Indore, NMIMS Indore 
                            </h4>
                            <ul className="progarmDetailList">
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Location.svg" alt="Location" />
                                        Location :<span className="fs-18 fw-400">Indore</span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Certification.svg" alt="Certification" />
                                        Certification :<span className="fs-18 fw-400"></span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Duration.svg" alt="Duration" />
                                        Duration :<span className="fs-18 fw-400">3 days </span></h6>
                                </li>
                                <li>
                                    <h6 className="fs-18 fw-600 textWhite"><img className="widthAuto"
                                        src="assets/images/pillarPage/Reidential.svg" alt="Reidential" />
                                        Participations :<span className="fs-18 fw-400">2500+</span></h6>
                                </li>
                               
                            </ul>
                           
                        </div>
                    </div>
                    
                </div>

            </section> */}



      {/* <section className="advenBootcampSec p-80-20 bgBlack pt-0">
                <div className="container">
                    <div className="advenBootcamp-outer">
                        <div className="advenBootcamp-innerLeft">
                            <img src="assets/images/pillarPage/progarm.png" alt="pillarProgram" />
                        </div>
                        <div className="advenBootcamp-innerRight">
                            <h3 className="fs-34 fw-600 pb-20 textOrg ">Leadership and Adventure Bootcamp</h3>
                            <p className="fs-16 fw-400 textWhite pb-20">By connecting students from various backgrounds and schools, our
                                flagship programmes engage students and actively involve them in the learning and development
                                process.</p>

                            <ul className="advenBootcampList">
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                                <li>
                                    <p className="fs-16 fw-400 textWhite">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}

      <Footer />

    </>
  )
}

export default OnlineChallenges