// PopupForm.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

// const PopupForm = ({ togglePopup }) => {
//     const [payload, setPayload] = useState({
//         person_name: "",
//         email: "",
//         enquiry: "",
//         enquiring_for: "",
//         school_name: "",
//         school_location: "",
//         contact_number: "",
//         message: "",
//         source: ""
//     });

//     const [formError, setFormError] = useState(false);
//     const [formSuccess, setFormSuccess] = useState(false);

//     const [sourceUrl, setSourceUrl] = useState('');

//     useEffect(() => {
//         setSourceUrl(window.location.href);
//         setPayload(prevPayload => ({
//             ...prevPayload,
//             source: window.location.href
//         }));
//     }, []);

//     const handleSubmit = async (event) => {
//         event.preventDefault();

//         const { person_name, email, enquiry, school_name, school_location, contact_number, message, enquiring_for } = payload;

//         // **Email validation regex**
//         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//         // **Contact number validation regex (adjust as needed)**
//         const contactNumberRegex = /^[0-9]{10}$/;

//         // **Check if any field is empty**
//         if (!person_name || !enquiry || !school_name || !school_location || !contact_number || !email || !message || !enquiring_for) {
//             setFormError("Please fill in all the fields.");
//             setFormSuccess(false);
//             return;
//         }

//         // **Validate email**
//         if (!emailRegex.test(email)) {
//             setFormError("Please enter a valid email address.");
//             setFormSuccess(false);
//             return;
//         }

//         // **Validate contact number**
//         if (!contactNumberRegex.test(contact_number)) {
//             setFormError("Please enter a valid contact number (10 digits).");
//             setFormSuccess(false);
//             return;
//         }

//         setFormError(false);

//         try {
//             const response = await axios.post(`https://server.mu20.co/user`, payload);
//             if (response.status === 201) {
//                 setFormSuccess(true);
//             } else {
//                 setFormSuccess(false);
//             }
//         } catch (error) {
//             console.log('An error occurred:', error);
//             setFormSuccess(false);
//         }
//     };

//     const handleChange = (e) => {
//         const { value, name } = e.target;
//         setPayload({ ...payload, [name]: value });
//     };

//     const handleSelect = (e) => {
//         let { value } = e.target;
//         setPayload(prevPayload => ({ ...prevPayload, enquiry: value }));
//     };

const PopupForm = ({ togglePopup }) => {
    const [payload, setPayload] = useState({
        person_name: "",
        email: "",
        enquiry: "",
        enquiring_for: "",
        school_name: "",
        school_location: "",
        contact_number: "",
        message: "",
        source: ""
    });

    const [formError, setFormErrors] = useState({
        person_name: "",
        email: "",
        enquiry: "",
        school_name: "",
        school_location: "",
        contact_number: "",
        message: "",
        enquiring_for: ""
    });

    const [formSuccess, setFormSuccess] = useState(false);
    const [sourceUrl, setSourceUrl] = useState('');

    useEffect(() => {
        setSourceUrl(window.location.href);
        setPayload(prevPayload => ({
            ...prevPayload,
            source: window.location.href
        }));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        let errors = { ...formError };

        // Reset errors
        Object.keys(errors).forEach(key => errors[key] = "");

        // **Email validation regex**
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const contactNumberRegex = /^[0-9]{10}$/;

        // **Check if any field is empty**
        Object.keys(payload).forEach(key => {
            if (!payload[key]) {
                errors[key] = "This field is required.";
            }
        });

        // **Validate email**
        if (payload.email && !emailRegex.test(payload.email)) {
            errors.email = "Please enter a valid email address.";
        }

        // **Validate contact number**
        if (payload.contact_number && !contactNumberRegex.test(payload.contact_number)) {
            errors.contact_number = "Please enter a valid contact number (10 digits).";
        }

        // Update errors state
        setFormErrors(errors);

        // If no errors, proceed with form submission
        if (!Object.values(errors).some(error => error)) {
            try {
                const response = await axios.post(`https://server.mu20.co/user`, payload);
                if (response.status === 201) {
                    setFormSuccess(true);
                } else {
                    setFormSuccess(false);
                }
            } catch (error) {
                console.log('An error occurred:', error);
                setFormSuccess(false);
            }
        } else {
            setFormSuccess(false);
        }
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setPayload({ ...payload, [name]: value });
    };

    return (

        <div className="contactForm">
            <button className="close" onClick={togglePopup}>&times;</button>
            <section className="bgBlack">
                <div className="contactPopup bglightBlack">
                    <div className={`contactForm ${formError ? 'form-error' : ''} ${formSuccess ? 'form-success' : ''}`}>
                        <div className={`form-message ${formError ? 'form-error' : ''} ${formSuccess ? 'form-success' : ''}`}>
                            <img src="assets/images/tick.png" alt="Success" className="success-image" />
                            <div className='thnx_msg'>Thank You</div>
                            <div className="success-message">
                                Thank you for reaching out to us! We’ve received your query and our team will get back to you as soon as possible.<br /><br />
                                Regards,<br />
                                Team MU20 School of Opportunity
                            </div>
                            <div className='success_logo'>
                                <img src="/assets/images/whitelogo@4x.png" alt="" />
                            </div>
                        </div>
                        <form className={formError ? 'form-error' : ''} onSubmit={handleSubmit}>

                            <div className="form_field">
                                <label for="fname">Name</label>
                                <input type="text" id="fname" placeholder="Name*" onChange={(e) => handleChange(e)} name='person_name' />
                                {formError.person_name && <span className="error-message">{formError.person_name}</span>}
                            </div>


                            <div className="form_field">
                                <label for="cars">You are inquiring as</label>
                                <select className='opt_form' name="enquiry" id="enquiry" onChange={(e) => handleChange(e)}>
                                    <option value="You are inquiring as" disabled selected>You are inquiring as*</option>
                                    <option value="Student">Student</option>
                                    <option value="Parent">Parent</option>
                                    <option value="Teacher">Teacher</option>
                                </select>
                                {formError.enquiry && <span className="error-message">{formError.enquiry}</span>}
                            </div>


                            <div className="form_field">
                                <label for="fname">School name (If you are a parent, kindly fill in your ward's school name)</label>
                                <input type="text" id="fname" placeholder="School name (If you are a parent, kindly fill in your ward's school name)*" onChange={(e) => handleChange(e)} name='school_name' />
                                {formError.school_name && <span className="error-message">{formError.school_name}</span>}
                            </div>



                            <div className="loaction">

                                <div className="form_field">
                                    <label for="location">School location</label>
                                    <input type="text" id="school-lacation" placeholder="School Location*" onChange={(e) => handleChange(e)} name='school_location' />
                                    {formError.school_location && <span className="error-message">{formError.school_location}</span>}
                                </div>

                                <div className="form_field">
                                    <label for="contact">Contact number</label>
                                    <input type="tel" id="contact-number" placeholder="Contact number*" onChange={(e) => handleChange(e)} name='contact_number' />
                                    {formError.contact_number && <span className="error-message">{formError.contact_number}</span>}
                                </div>


                            </div>

                            <div className="loaction">

                            <div className="form_field">
                                <select className='opt_form enquiring' name="enquiring_for" id="enquiring_for" onChange={(e) => handleChange(e)}>
                                    <option value="Enquiring For" disabled selected>Enquiring For*</option>
                                    <option value="OxfordMUN_India_24">OxfordMUN India 24</option>
                                    <option value="MU20_Opportunity_Summit_24">MU20 Opportunity Summit 24</option>
                                    <option value="Leadership_and_Adventure_Bootcamp">Leadership and Adventure Bootcamp</option>
                                    <option value="Young_Leaders_Bootcamp">Young Leaders Bootcamp</option>
                                    <option value="Craft_the_Future_Challenge_24">Craft the Future Challenge 24</option>
                                    <option value="Oxford_MUN_Pre-Challenge">Oxford MUN Pre-Challenge</option>
                                    <option value="Educators_Leadership_Retreat">Educators Leadership Retreat (EdLR)</option>
                                    <option value="MU20_Fellowship">MU20 Fellowship</option>
                                    <option value="Residential_Programs">Residential Programs</option>
                                </select>
                                {formError.enquiring_for && <span className="error-message">{formError.enquiring_for}</span>}
                            </div>


                            <div className="form_field">
                                <label for="email">Email</label>
                                <input type="email" id="email" placeholder="Email*" onChange={(e) => handleChange(e)} name='email' />
                                {formError.email && <span className="error-message">{formError.email}</span>}
                            </div>

                            </div>

                          

                            <div className="form_field">
                                <label for="Source">Source</label>
                                <input value={sourceUrl} className='source_field' readOnly type="text" id="source" placeholder="Source" onChange={(e) => handleChange(e)} name='source' />
                                {formError.Source && <span className="error-message">{formError.Source}</span>}
                            </div>


                            <div className="form_field">
                                <label for="message">Add a message </label>
                                <textarea type="textarea" placeholder="Add a message *" onChange={(e) => handleChange(e)} name='message'></textarea>
                                {formError.message && <span className="error-message">{formError.message}</span>}

                            </div>

                            <button type="submit" onClick={handleSubmit} className="formSubmit">Submit</button>
                        </form>

                    </div>

                </div>



            </section>

        </div>




    );
};

export default PopupForm;
